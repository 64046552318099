import React, { useCallback, useState } from "react"
import styled, { css } from "styled-components"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import ReactPlayer from "react-player"

import Align from "../Layout/Align"

import Button from "../Button"

import Image1 from "../../images/image1.jpg"
import Image2 from "../../images/image2.jpg"
import Image3 from "../../images/image3.jpg"
import Video from "../../assets/images/video-thumbnail.jpg"
import mp4 from "../../video/video.mp4"

import Image4 from "../../images/image4.jpg"

const KVWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 150px 0;
  &::before {
    content: '';
    background-color: #ddd;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 1px;
  }
`;

const Column = styled.div`
  &:first-of-type {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    @media screen and (max-width: 1400px) and (min-width: 1201px) {
      width: 50%;
      padding-right: 0;
    }
    @media screen and (max-width: 1200px) {
      width: 50%;
      padding-right: 0;
    }
  }
`

const KVTitle = styled.p`
  font-size: 36px;
  line-height: 47px;
  color: #191a1a;
  @media screen and (max-width: 1200px) {
    font-size: 24px;
  }
`

const KVDesc = styled.p`
  font-size: 16px;
  line-height: 30px;
  color: #999999;
  margin-top: 50px;
  @media screen and (max-width: 1200px) {
  margin-top: 10px;
  }
`

const BtnLearnMore = styled(Button)`
  margin-top: 60px;
`

const Image = styled.img`
    ${props =>
      props.image1 &&
      css`
        max-width: 100%;
        top: 0;
        right: 0;
        max-width: 100%;
        position: absolute;
      `}
    ${props =>
      props.image2 &&
      css`
        max-width: 100%;
        top: 0;
        right: 281px;
        max-width: 100%;
        position: absolute;
      `}
    ${props =>
      props.image4 &&
      css`
        height: 100%;
        display: block;
        text-align: center;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      `}
`

const VidPlayer = styled(ReactPlayer)`
  height: 100%;
  display: block;
  text-align: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${props =>
    !props.isAnimated &&
    css`
      pointer-events: none;
      .react-player__shadow {
        display: none !important;
      }
    `}
  ${props =>
    props.isAnimated &&
    css`
      pointer-events: pointer;
      width: 80% !important;
      height: 500px !important;
      video {
        background-color: black;
      }
      .react-player__shadow {
        display: flex;
      }
    `}
`

const ImageMask = styled.div`
  /* height: 400px; */
  overflow: hidden;
  position: absolute;
  width: 250px;
  top: 110px;
  z-index: 999;
`

const Div = styled.div`
  position: relative;
  z-index: 2;
`

const Img = styled.img`
  max-width: 100%;
  opacity: 0;
`

const KeyVisual = props => {
  const [animated, setAnimated] = useState(false)
  const animate = useCallback(() => {
    setAnimated(true)
  }, [animated, setAnimated])

  return (
    <Div>
      <KVWrapper id="trigger">
        <Column>
          <KVTitle>{props.title}</KVTitle>
          <KVDesc>{props.desc}</KVDesc>
          <BtnLearnMore>Learn More</BtnLearnMore>
        </Column>
      </KVWrapper>
    </Div>
  )
}

export default KeyVisual

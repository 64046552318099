import React from "react"

import Layout from "../components/Layout"
import '../styles/style.css'
import Blog from '../components/Blog/Blog'
import A from '../components/Button/A'
import Btn from '../components/Button/Btn'
import Margin from "../components/Layout/Margin"
import Align from '../components/Layout/Align'
import Cta from "../components/Cta/Index"
import { Row } from "../components/Layout/Grid"
import Feature from "../components/Slider/Index"
import {Wrapper, CustomWrapper} from "../components/Wrapper"
import KeyVisual from "../components/KeyVisual"
/*
 * IMAGES
*/

import Banner from "../assets/images/gss-banner.png"
import Video from "../assets/images/video-thumbnail.jpg"

const IndexPage = () => {
  return (
    <Layout>
      <Wrapper>
        <KeyVisual title="Vix te fuisset inimicus. Nam ne prima novum appetere, doctus aliquid id 
duo." desc="Eam te iisque persequeris, agam expetenda erroribus ei sea. Mel vero etiam audiam in, dicta quodsi vim no, ex sed numquam voluptua. Mei veri fuisset et, sit ea vide mutat adhuc. Id omnis mazim adolescens duo, pri percipitur necessitatibus ut."/>
      </Wrapper>

      {/* SLIDER */}
      <CustomWrapper full margin={'90px 0 0'}>
        <Feature />
      </CustomWrapper>
      {/* EO SLIDER */}

      <CustomWrapper margin={'100px auto 0'}>
        {/* CTA */}
        <Cta path={Banner}>
            <Row vcenter hlign={'center'} height={'100%'}>
                <p>Eam te iisque persequeris, agam expetenda erroribus ei sea. Mel vero etiam audiam in, dicta<br />quodsi vim no, ex sed numquam voluptua.</p>
            </Row>
        </Cta>
        {/* EO CTA */}

        {/* BLOG */}
        <div style={{maxWidth: 1000, margin:'100px auto 0'}}>
          <Blog />
          <Margin value={'60px 0 0'} />
          <Align position={'center'}>
            <A href="/blog"><Btn primary>READ MORE</Btn></A>
          </Align>
          <Margin value={'30px 0 0'} />
        </div>
      </CustomWrapper>
  </Layout>
  )
}

export default IndexPage
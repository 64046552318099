import styled from 'styled-components'
import PropTypes from 'prop-types'

const Margin = styled.div`
    margin: ${props => props ? props.value : ''}
`

Margin.propTypes = {
    value: PropTypes.string
}
export default Margin




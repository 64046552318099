import React from 'react';
import PropTypes from 'prop-types';

import A from './A';
import Btn from './Btn';
// import Wrapper from './Wrapper';
import BtnImg from './BtnImg';

import arrow from "./img/arrow.png";
const Button = props => {
    return (
        <A href="#">
            <Btn {...props}>
                {props.children}
                {props.primary ? '' : (<BtnImg src={arrow} alt='' />)}
            </Btn>
        </A>
    )
}


Button.propTypes = {
    primary: PropTypes.string,
    children: PropTypes.string.isRequired,
};

export default Button;
import React, { Component } from 'react'
import Slider from 'react-slick'
import { createGlobalStyle } from 'styled-components'
import prev from '../../assets/images/prev.png'
import next from '../../assets/images/next.png'
import {Row} from "../Layout/Grid"
import { P } from '../Typo/P'
import { H2, H3 } from '../Typo/Headers'
import Margin from '../Layout/Margin'
//import Color from '../Variables/Color'
import { colorLightBlue, colorDarkGray } from '../Variables/Variables'
import window from 'global'

const SliderStyle = createGlobalStyle`
    .slick-list{
        padding: 10px 0 10px 10px !important;
    }
    .slick-initialized .slick-slide {
       margin: 0 26px;
       padding: 42px 40px 75px;
       box-shadow: 0px 5px 16.56px 1.44px rgba(0,0,0,0.1);
       > div {
            width: 220px !important;
       }
       h3 {
            color: ${ colorLightBlue };
            font-size: 20px;
            line-height: 1.2;
            font-weight: bold;
        }
       p {
           line-height: 1.3125;
           letter-spacing: 0.025em;
           margin-top: 20px;
       }
    }
    .slider-text {
        padding-left: ${ props => ((props.width - props.conWidth) / 2) + 60 }px;
        padding-right: 45px;
        .slider-text-item {
            width: 455px;
            padding-top: 57px;
        }
        h2 {
            line-height: 1.3;
        }
        p:last-of-type {
            line-height: 1.3;
        }
        button {
            background-color: transparent;
            border: none;
            color: transparent;
            &.prev {
                &::after {
                    background: url(${prev}) no-repeat center/cover;
                }
            }
            &.next {
                &::after {
                    background: url(${next}) no-repeat center/cover;
                }
            }
            &::after {
                content: '';
                display: inline-block;
                height: 12px;
                width: 16px;
            }
        }
    }
`

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    prevArrow: false,
    nextArrow: false,
    variableWidth: true
  };

export default class Feature extends Component {
      
      state = {
        width: window.innerWidth,
        containerWidth: window.innerWidth > 1280 ? 1400 : 1300
      }

      prevSlide = () => {
        this.slider.slickPrev();
      }

      nextSlide = () => {
        this.slider.slickNext();
      }

      getWindowWidth = () => {
          this.setState({
              width: window.innerWidth
          })
      }

      componentDidMount() {
          window.addEventListener('resize', this.getWindowWidth);
      }

      componentWillUnmount() {
        //window.removeEventListener("resize", this.updateDimensions.bind(this));
      }

      render() {
        return (
            <Row nowrap>
                <SliderStyle width={this.state.width} conWidth={this.state.containerWidth}/>
                    <div className={'slider-text'}>
                        <div className={'slider-text-item'}>
                            <P font={'14px'} color={colorDarkGray} bold>Eam te iisque persequeri </P>
                            <Margin value={'40px 0 0'} />
                            <H2 font={['36px','700']}>Vix te fuisset inimicus.<br />Nam ne doctus aliquid id duo. </H2>
                            <Margin value={'55px 0 0'} />
                            <P>Mei veri fuisset et, sit ea vide mutat adhuc. Id omnis mazim adolescens duo, pri percipitur necessitatibus ut.</P>
                            <Margin value={'57px 0 0'} />
                            <div style={{textAlign: 'right'}}>
                                <button className="prev" onClick={this.prevSlide}></button>
                                <button className="next" onClick={this.nextSlide}></button>
                            </div>
                        </div>
                    </div>
                    <Slider {...settings} ref={c => this.slider = c}>
                    <div>
                        <H3>STRATEGY & CONSULTANCY</H3>
                        <P>Eam te iisque persequeris, agam expetenda erroribus ei sea. Mel vero etiam audiam in, dicta quodsi vim no, ex sed numquam voluptua. Mei veri fuisset et, sit ea vide mutat adhuc. Id omnis mazim adolescens duo, pri percipitur necessitatibus ut.</P>
                    </div>
                    <div>
                        <H3>STRATEGY & CONSULTANCY</H3>
                        <P>Eam te iisque persequeris, agam expetenda erroribus ei sea. Mel vero etiam audiam in, dicta quodsi vim no, ex sed numquam voluptua. Mei veri fuisset et, sit ea vide mutat adhuc. Id omnis mazim adolescens duo, pri percipitur necessitatibus ut.</P>
                    </div>
                    <div>
                        <H3>STRATEGY & CONSULTANCY</H3>
                        <P>Eam te iisque persequeris, agam expetenda erroribus ei sea. Mel vero etiam audiam in, dicta quodsi vim no, ex sed numquam voluptua. Mei veri fuisset et, sit ea vide mutat adhuc. Id omnis mazim adolescens duo, pri percipitur necessitatibus ut.</P>
                    </div>
                </Slider>
            </Row>
          );
      } 
}
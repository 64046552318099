import styled, {css} from 'styled-components'
import { colorBlack } from '../Variables/Variables'

//font @args(size, weight)

export const P = styled.p`
    font-size: ${props => props.font};
    color: ${props => props.color};
    font-weight: 400;
    ${props => props.bold && css`
        font-weight: 700;
    `}
    ${props => props.black && css`
        font-weight: 900;
    `}
`

P.defaultProps = {
    fontSize: '16px',
    color: colorBlack
}
import styled from 'styled-components'

const Cta = styled.div`
    background: url(${ props => props.path}) no-repeat center/cover;
    color: white;
    height: ${props => props.height};
    p {
        text-align: center;
        line-height: 1.39;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.025em;
    }
`
Cta.defaultProps = {
    path: '',
    height: '300px'
}

export default Cta;
import React from 'react';
import styled from "styled-components"

const BtnImgStyle = styled.img`
  position: relative;
  display: block;
`;

const Div = styled.div`
  position: relative;
  margin-left: 43px;
  display: inline-block;
  &::before {
      content: '';
      background-color: #00a3e9;
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
  }
`;


const BtnImg = props => {
    return (
        <Div>
            <BtnImgStyle src={props.src}/>
        </Div>
    );
}



export default BtnImg;

import styled from 'styled-components'
import { colorBlack } from '../Variables/Variables';

//font props @args(size, weight)

export const H1 = styled.h1`
    font-size: ${props => props.font[0]};
    color: ${props => props.color};
    font-weight: ${props => props.font[1]};
`
export const H2 = styled.h2`
    font-size: ${props => props.font[0]};
    color: ${props => props.color};
    font-weight: ${props => props.font[1]};
`
export const H3 = styled.h3`
    font-size: ${props => props.font[0]};
    color: ${props => props.color};
    font-weight: ${props => props.font[1]};
`

H1.defaultProps = {
    font: ['48px', '400'],
    color: colorBlack
}
H2.defaultProps = {
    font: ['36px', '400'],
    color: colorBlack
}
H3.defaultProps = {
    font: ['28px', '400'],
    color: colorBlack
}